const supplierRoutes = [
  {
    path: '/suppliers',
    name: 'SupplierListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      title: 'Proveedores'
    },
    component: () => import('@/modules/supplier/views/SupplierListCreate')
  },
  {
    path: '/suppliers/:id',
    name: 'SupplierRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      title: 'Detalles y actualización de proveedores'
    },
    component: () => import('@/modules/supplier/views/Main')

  }
]
export default supplierRoutes