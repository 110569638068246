import axios from 'axios'
import http from './http'

const auth = {
  signUp: (user, invitation = null) => {
    return new Promise((resolve, reject) => {
      const params = {}
      if (invitation) {
        params.invitation = invitation
      }
      http.post('/auth/registration/', user, { params: params })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  getAuth: (resource, payload) => {
    return new Promise((resolve, reject) => {
      http.post(resource, payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  getRefreshToken: (token) => {
    return new Promise((resolve, reject) => {
      http.post('/token/refresh/', { refresh: token })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  tokenVerify: (token) => {
    return new Promise((resolve, reject) => {
      http.post('/token/verify/', { token: token })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  bulkInvitation: (persons) => {
    return new Promise((resolve, reject) => {
      http.post('/invitations/send_bulk/', { persons: persons, url: process.env.VUE_APP_API_BASE.replace(/api/g, 'app')})
      .then(() => {
        resolve()
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  acceptInvitation: (key, payload) => {
    return new Promise((resolve, reject) => {
      let currentPayload = {...payload}
      delete currentPayload.emal
      http.post(`${key}/accept-invite/`, { password1: currentPayload.password1, password2: currentPayload.password2 })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  passwordReset: (email) => {
    return new Promise((resolve, reject) => {
      http.post('/password/reset/', { email: email, url: process.env.VUE_APP_API_BASE.replace(/api/g, 'app') })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  passwordResetConfirm: (payload) => {
    return new Promise((resolve, reject) => {
      http.post('/auth/password/reset/confirm/', {
        uid: payload.uid,
        token: payload.token,
        new_password1: payload.new_password1,
        new_password2: payload.new_password2
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  getUser: () => {
    return new Promise((resolve, reject) => {
      http.get('/auth/user/')
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  patchUser: (payload) => {
    return new Promise((resolve, reject) => {
      http.patch('/auth/user/', {
        first_name: payload.first_name,
        last_name: payload.last_name
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  postUserPassword: (payload) => {
    return new Promise((resolve, reject) => {
      http.post('/auth/password/change/', {
        new_password1: payload.new_password1,
        new_password2: payload.new_password2,
        old_password: payload.old_password
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  retrieveTrace: () => {
    return new Promise((resolve, reject) => {
      axios.get(process.env.VUE_APP_API_TRACE_URL)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  }
}

const general = {
  getList: (resource, query = {}) => {
    return new Promise((resolve, reject) => {
      http.get(`/${resource}/`, { params: query })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  getDetail: (resource, id) => {
    return new Promise((resolve, reject) => {
      http.get(`/${resource}/${id}/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  postObject: (resource, payload) => {
    return new Promise((resolve, reject) => {
      http.post(`/${resource}/`, payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }
}

const account = {
  list: (resource, query = {}, responseType = 'json') => {
    return new Promise((resolve, reject) => {
      let request = `/${resource}/`
      http.get(request, { params: query, responseType: responseType })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  retrieve: (resource, id, detail, query = {}) => {
    return new Promise((resolve, reject) => {
      let request = `/${resource}/${id}/`
      if (detail) {
        request += `${detail}/`
      }
      http.get(request, { params: query })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  get: (resource, query = {}) => {
    return new Promise((resolve, reject) => {
      http.get(`/${resource}`, { params: query })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  create: (resource, payload, detail = null, id = null, headers = {}, query = {}) => {
    return new Promise((resolve, reject) => {
      let request = `/${resource}/`
      if (detail && id) {
        request += `${id}/${detail}/`
      }
      http.post(request, payload, { params: query }, headers)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  update: (resource, id, payload, headers = {}) => {
    return new Promise((resolve, reject) => {
      let request = `/${resource}/${id}/`
      http.patch(request, payload, headers)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  destroy: (resource, id, query = {}) => {
    return new Promise((resolve, reject) => {
      http.delete(`/${resource}/${id}/`, { params: query })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  downloadDocuments: (resource, payload, query = {}) => {
    return new Promise((resolve, reject) => {
      http.post(resource, payload, { params: query })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }
}

export { auth, general, account }
